@tailwind base;
@tailwind components;
@tailwind utilities;

/*--------------------BACKGROUND COLORS------------------------*/
.bg-theme
{
    background-color: #e76a24;
}

.bg-secondary
{
    background-color: #4194a5;
}

.bg-dark-grey
{
    background-color: #303030;
}

.bg-light-grey
{
    background-color: #fbfbfb;
}

.bg-success
{
    background-color: #198754;
}

.bg-danger
{
    background-color: #dc3545;
}

.border-theme
{
    border: 1px solid #e76a24;
}

.border-theme-secondary
{
    border: 1px solid #4194a5;
}

.border-theme-light
{
    border: 1px solid #ffffff;
}

.bg-theme-100
{
    background-color: rgba(231, 106, 36, 0.2 );
}

.bg-secondary-100
{
    background-color: rgba(65, 148, 165, 0.2 );
}

.bg-mg
{
    background: rgb(224 224 224 / 16%);
}

/*--------------------Hover/Focus/Active------------------------*/
/*  @variants active { */
@layer utilities {

    .text-theme {
        color: #e76a24;
    }

    .text-theme-teal
    {
        color: #4194a5;
    }

    .text-dark-grey
    {
        color: #303030;
    }

}

/*@variants group-hover, hover, focus {*/
@layer utilities {
    .bg-dark-grey
    {
        background-color: #303030;
    }

    .bg-theme
    {
        background-color: #e76a24;
    }

    .bg-theme-hover
    {
        background: #4194a5;
    }

    .bg-light-grey
    {
        background-color: #fbfbfb;
    }

    .border-theme
    {
        border: 1px solid #e76a24;
    }

    .border-theme-secondary
    {
        border: 1px solid #4194a5;
    }

    .bg-mg
    {
    background: rgb(224 224 224 / 16%);
    }
}