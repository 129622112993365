.buttons{
    background: #e76a24;
}
.buttons:hover{
    background: #e76a24;
}
.cancelButton{
    background: #F9F9F9;
}
.cancelButton:hover{
    background: #F2F2F2;
}
.notActiveButton{
    background: #FFE8DB;
}
.ag-theme-alpine .ag-row-selected,
.ag-theme-alpine .ag-row-odd.ag-row-selected {
    background-color: #FEC19F !important;
}

.ag-theme-alpine .ag-row:hover,
.ag-theme-alpine .ag-row-odd:hover {
    background-color: #FFF7F3 !important;
}

.ag-theme-alpine .ag-root-wrapper {
    @apply border-none text-base leading-6 !important;
}

.ag-theme-alpine .ag-row {
    @apply border-none !important;
}

.shadowMenu{
    box-shadow: 0px 32px 32px 0px #BFBFBF;
}
.downloadAgentButton:hover{
    background: #F2F2F2;
}
.checkbox{
    transform: scale(1.5);
    @apply xl:transform-none;
}
.dropdown{
    @apply rounded-lg border border-black border-opacity-25 border-solid h-10;
}
.dropdown-auto-height{
    @apply rounded-lg border border-black border-opacity-25 border-solid;
}
.timezone{
    @apply rounded-sm border border-black border-opacity-5 border-solid;
}
.chartContainer{
    width: 95%;
    min-height: 700px;
}
.custom-tooltip {
    position: absolute;
    width: 700px;
    min-width: fit-content;
    height: fit-content;
    border: 1px solid lightgray;
    pointer-events: none;
    transition: opacity 1s;
}

.custom-tooltip.ag-tooltip-hiding {
    opacity: 0;
}

.custom-tooltip p {
    margin: 5px;
    font-size: 1rem;
}
.agentInsertedWithoutLicenseInValidateTrue {
    background-color: #e8e8e8 !important;
}
div.ag-cell-wrapper{
    height: 100%;
}
.customMuiLoadingButtonNoPaddingAndMinWidth{
    padding: 0 !important;
    min-width: 0 !important;
}


