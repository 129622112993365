/*--------------------BUTTONS-------------------------*/
.cta-1-btn
{
    font-size: 20px;
}

.cta-1-btn:hover, .cta-1-btn:focus, .cta-1-btn:active
{
    color: #ffffff;
    background-color: #e76a24;
}