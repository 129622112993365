/*--------------------HOME-------------------------*/
.hero-bg
{
    background-image: url(./bg/work-731198_1920.jpeg);
    background-size: cover;
}

.hero-bg > div
{
    background-color: rgba(0,0,0,0.6);
    height: 100vh;
}

.hero-bg-2
{
    background-image: url(./bg/computer-1209641_1920-1024x683.jpeg);
    background-size: cover;
}

.hero-bg-2 > div
{
    background-color: rgba(0,0,0,0.6);
}

.slider
{
    height: 250px;
}

.flip-card {
    height: 450px;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.flip-card-front {
    color: black;
}

.flip-card-back {
    color: white;
    transform: rotateY(180deg);
}

/*--------------------ABOUT US-------------------------*/
.banner-about
{
    background-image: url(./bg/pittsburgh-4426848_1920.jpg);
    background-size: cover;
    background-position: 0px -110px;
    background-repeat: no-repeat;
}

.banner-about > div
{
    background-color: rgba(0,0,0,0.5);
}

/*--------------------PRODUCTS-------------------------*/
.banner-product
{
    background-image: url(./bg/security-2168233_1920.jpg);
    background-size: cover;
    background-position: 0px -160px;
}

.banner-product > div
{
    background-color: rgba(0,0,0,0.5);
}

.banner-questions
{
    background-image: url(./bg/question-mark-1872634_1920.jpeg);
    background-size: cover;
    background-position: 0px -160px;
}

.banner-questions > div
{
    background-color: rgba(0,0,0,0.5);
}

/*--------------------7 Questions-------------------------*/
.banner-questions-title
{
    background-image: url(./bg/question-mark-1872634_1920.jpeg);
    background-size: cover;
    background-position: 0px -160px;
}

.banner-questions-title > div
{
    background-color: rgba(0,0,0,0.5);
}

/* Tab content - closed */
.tab-content {
    max-height: 0;
    -webkit-transition: max-height .35s;
    -o-transition: max-height .35s;
    transition: max-height .35s;
}
/* :checked - resize to full height */
.tab input:checked ~ .tab-content {
    max-height: 100vh;
}
/* Label formatting when open */
.tab input:checked + label{
    /*@apply text-xl p-5 border-l-2 border-indigo-500 bg-gray-100 text-indigo*/
    font-size: 1.25rem; /*.text-xl*/
    padding: 1.25rem; /*.p-5*/
    border-left-width: 2px; /*.border-l-2*/
    border-color: #4194a5; /*.border-indigo*/
    background-color: #f8fafc; /*.bg-gray-100 */
    color: #4194a5; /*.text-indigo*/
}
/* Icon */
.tab label::after {
    float:right;
    right: 0;
    top: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5;
    font-size: 1.25rem;
    text-align: center;
    -webkit-transition: all .35s;
    -o-transition: all .35s;
    transition: all .35s;
}
/* Icon formatting - closed */
.tab input[type=checkbox] + label::after {
    content: "+";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
}
.tab input[type=radio] + label::after {
    content: "\25BE";
    font-weight:bold; /*.font-bold*/
    border-width: 1px; /*.border*/
    border-radius: 9999px; /*.rounded-full */
    border-color: #b8c2cc; /*.border-grey*/
}
/* Icon formatting - open */
.tab input[type=checkbox]:checked + label::after {
    transform: rotate(315deg);
    background-color: #4194a5;
    color: #f8fafc; /*.text-grey-lightest*/
}
.tab input[type=radio]:checked + label::after {
    transform: rotateX(180deg);
    background-color: #4194a5;
    color: #f8fafc; /*.text-grey-lightest*/
}

.hero-bg-3
{
    background-image: url(./bg/matrix-3408055_1920.jpg);
    background-size: cover;
}

.hero-bg-3 > div
{
    background-color: rgba(0,0,0,0.5);
}

.hero-bg-4
{
    background-image: url(./bg/hacker-1944688_1280.jpg);
    background-size: cover;
}

.hero-bg-4 > div
{
    background-color: rgba(0,0,0,0.4);
}

.hero-bg-5
{
    background-image: url(./bg/communication-4871245_1920.jpg);
    background-size: cover;
}

.hero-bg-5 > div
{
    background-color: rgba(0,0,0,0.4);
}

/*--------------------MSP/MSSP------------------------*/
.banner-msp-mssp
{
    background-image: url(./bg/AdobeStock_272289224.jpeg);
    background-size: cover;
    background-position: 0px -160px;
}

.banner-msp-mssp > div
{
    background-color: rgba(0,0,0,0.4);
}

/*--------------------For Tech Partners------------------------*/
.banner-for-tech
{
    background-image: url(./bg/AdobeStock_385625051.jpeg);
    background-size: cover;
    background-position: 0px -140px;
}

.banner-for-tech > div
{
    background-color: rgba(0,0,0,0.4);
}

/*--------------------RESOURCES-------------------------*/
.banner-resources
{
    background-image: url(./bg/analytics-3088958_1920.jpg);
    background-size: cover;
}

.banner-resources > div
{
    background-color: rgba(0,0,0,0.5);
}

/*--------------------CONTACT US-------------------------*/
.banner-contact
{
    background-image: url(./bg/communication-4871245_1920.jpg);
    background-size: cover;
}

.banner-contact > div
{
    background-color: rgba(0,0,0,0.5);
}
.rr-logo-bg{
    background: #FFE8DB;
}
.text-color-orange-100{
    color: #FC6510;
}
.text-color-red-100{
    color:#F64949;
}
.bg-color-red-10{
    background: #FEEDED;
}
.width-fit-content{
    width: fit-content
}
.mfaMaxWidth {
    max-width: 20%;
}

/*---------------------------------------------------------------------*/
/*--------------------RESPONSIVE MEDIA QUERIES-------------------------*/
/*---------------------------------------------------------------------*/

/*---2X-Large---*/
@media (max-width: 1537px) {
    .banner-about
    {
        background-image: url(./bg/pittsburgh-4426848_1920.jpg);
        background-size: cover;
        background-position: 0px -110px;
        background-repeat: no-repeat;
    }

    .banner-product
    {
        background-image: url(./bg/security-2168233_1920.jpg);
        background-size: cover;
        background-position: 0px -160px;
    }

    .banner-msp-mssp
    {
        background-image: url(./bg/AdobeStock_272289224.jpeg);
        background-size: cover;
        background-position: 0px -160px;
    }

    .banner-for-tech
    {
        background-image: url(./bg/AdobeStock_385625051.jpeg);
        background-size: cover;
        background-position: 0px -140px;
    }
}

/*---X-Large---*/
@media (max-width: 1281px) {
    .banner-about
    {
        background-image: url(./bg/pittsburgh-4426848_1920.jpg);
        background-size: cover;
        background-position: 0px -110px;
        background-repeat: no-repeat;
    }

    .banner-product
    {
        background-image: url(./bg/security-2168233_1920.jpg);
        background-size: cover;
        background-position: 0px -160px;
    }

    .banner-msp-mssp
    {
        background-image: url(./bg/AdobeStock_272289224.jpeg);
        background-size: cover;
        background-position: 0px -160px;
    }

    .banner-for-tech
    {
        background-image: url(./bg/AdobeStock_385625051.jpeg);
        background-size: cover;
        background-position: 0px -140px;
    }
}

/*---Large---*/
@media (max-width: 1025px) {
    .banner-about
    {
        background-image: url(./bg/pittsburgh-4426848_1920.jpg);
        background-size: cover;
        background-position: 0px -110px;
        background-repeat: no-repeat;
    }

    .banner-product
    {
        background-image: url(./bg/security-2168233_1920.jpg);
        background-size: cover;
        background-position: 0px -160px;
    }

    .banner-msp-mssp
    {
        background-image: url(./bg/AdobeStock_272289224.jpeg);
        background-size: cover;
        background-position: 0px -160px;
    }

    .banner-for-tech
    {
        background-image: url(./bg/AdobeStock_385625051.jpeg);
        background-size: cover;
        background-position: 0px -140px;
    }
}

/*---Medium---*/
@media (max-width: 769px) {
    .banner-about
    {
        background-image: url(./bg/pittsburgh-4426848_1920.jpg);
        background-size: cover;
        background-position: 0px 0px;
        background-repeat: no-repeat;
    }

    .banner-product
    {
        background-image: url(./bg/security-2168233_1920.jpg);
        background-size: cover;
        background-position: 0px 0px;
    }

    .banner-msp-mssp
    {
        background-image: url(./bg/AdobeStock_272289224.jpeg);
        background-size: cover;
        background-position: 0px 0px;
    }

    .banner-for-tech
    {
        background-image: url(./bg/AdobeStock_385625051.jpeg);
        background-size: cover;
        background-position: 0px 0px;
    }
}

/*---Small---*/
@media (max-width: 641px) {
    .banner-about
    {
        background-image: url(./bg/pittsburgh-4426848_1920.jpg);
        background-size: cover;
        background-position: 0px 0px;
        background-repeat: no-repeat;
    }

    .banner-product
    {
        background-image: url(./bg/security-2168233_1920.jpg);
        background-size: cover;
        background-position: 0px 0px;
    }

    .banner-msp-mssp
    {
        background-image: url(./bg/AdobeStock_272289224.jpeg);
        background-size: cover;
        background-position: 0px 0px;
    }

    .banner-for-tech
    {
        background-image: url(./bg/AdobeStock_385625051.jpeg);
        background-size: cover;
        background-position: 0px 0px;
    }
}

/*---X-Small---*/
@media (max-width: 639px) {
    .banner-about
    {
        background-image: url(./bg/pittsburgh-4426848_1920.jpg);
        background-size: cover;
        background-position: 0px 0px;
        background-repeat: no-repeat;
    }

    .banner-product
    {
        background-image: url(./bg/security-2168233_1920.jpg);
        background-size: cover;
        background-position: 0px 0px;
    }

    .banner-msp-mssp
    {
        background-image: url(./bg/AdobeStock_272289224.jpeg);
        background-size: cover;
        background-position: 0px 0px;
    }

    .banner-for-tech
    {
        background-image: url(./bg/AdobeStock_385625051.jpeg);
        background-size: cover;
        background-position: 0px 0px;
    }
}
.textContentSectionWrapper {
    width: 100%;
    max-width: 70rem;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
    padding: 2rem;
    word-break: break-word;
    row-gap: 25px;
}
.textContentHeaderAndParagraphFlex {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
}
.textContentH2 {
    font-family: "Open Sans", sans-serif;
    font-size: 3rem;
    font-weight: 600;
}
.textContentParagraph {
    color: #202830;
    font-size: 1.5rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    text-align: left;
}
.linkColor {
    color: #e76a24;
}
@media (max-width: 700px){
    .textContentH2 {
        font-family: "Open Sans", sans-serif;
        font-size: 2.5rem;
    }
    .textContentParagraph {
        color: #202830;
        font-size: 1.25rem;
        font-weight: 400;
        font-family: "Open Sans", sans-serif;
        text-align: left;
    }
}