@tailwind base;
@tailwind components;
@tailwind utilities;

/*--------------------TYPOGRAPHY-------------------------*/
.body-font
{
    font-family: 'Open Sans', sans-serif!important;
}

.text-theme {
    color: #e76a24;
}

.text-theme-teal
{
    color: #4194a5;
}

.text-dark-grey
{
    color: #303030;
}

/*--------------------Hover/Focus/Active------------------------*/
/*@variants group-hover, hover, focus {*/
@layer utilities {
    .text-theme
    {
        color: #e76a24;
    }

    .text-theme-teal
    {
        color: #4194a5;
    }

    .text-dark-grey
    {
        color: #303030;
    }
}