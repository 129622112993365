/*--------------------GLOBAL------------------------*/
body
{
    font-family: 'Open Sans', sans-serif!important;
}

/*--------------------FORM-------------------------*/
.required-text
{
    color: #c81313;
}

label
{
    font-weight: 600;
}

/*--------------------HEADER-------------------------*/
.logo
{
    width: 155px;
}
.ccLogoCell
{
    width: 22px;
}

.mobile-logo
{
    margin-left: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
}

/*--------------------MAIN NAVIGATION-------------------------*/
#main-navigation ul li
{
    display: inline-block;
}

#main-navigation ul li a
{
    color: #4194a5;
    font-weight: 600;
    font-size: 17px;
}

#main-navigation ul li a.active
{
    color: #e76a24;
}

#main-navigation > ul > li > ul
{
    display: none;
    visibility: hidden;
    opacity: 0;
}

#main-navigation > ul > li:hover > ul, #main-navigation > ul > li > ul:hover
{
    display: block;
    visibility: visible;
    opacity: 1;
}

#main-navigation > ul > li > ul > li
{
    clear: both;
    width: 100%;
}

#main-navigation ul li a:hover, #main-navigation ul li a:focus, #main-navigation ul li a:active
{
    color: #e76a24;
}

.mega-menu1 {
    display: none;
    left: 200px;
    position: absolute;
    text-align: left;
    width: 60%;
}

#main-navigation ul .hoverable {
    position: static;
}

#main-navigation ul .hoverable > a:after {
    font-size: 10px;
    padding-left: 6px;
    position: relative;
    top: -1px;
}

#main-navigation ul .hoverable:hover .mega-menu1 {
    display: block;
}

/*--------------------FOOTER-------------------------*/
.title-font
{
    color: #e76a24;
    font-weight: 500;
    font-style: italic;
    font-size: 18px;
}

.footer-links ul li a
{
    font-weight: 400;
    font-size: 16px;
}

.footer-links ul li a:hover, .footer-links ul li a:focus, .footer-links ul li a:active
{
    color: #e76a24;
}